<template>
<div>
	<div v-if="isLoading">
      <CanPayLoader/>
	</div>
  <div class="container" style="height:90vh;">
		<div v-if="show === 'registration_success'">
			<success :sessionId="sessionID" v-if="!isLoading"></success>
		</div>
	</div>
</div>
</template>
<script>
import { isMobile } from "mobile-device-detect";
import Success from "../Registration/Success.vue";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
	name: "Success",
	data() {
		return {
			mobile: false,
			show: 'registration_success',
			sessionID: localStorage.getItem('session_data'),
			isLoading: false,
			fullPage: true,
		};
	},
	components: {
		success: Success,
		Loading,
		CanPayLoader
	},  
 	mounted: function () {
		let self = this;
		self.$root.$emit("show_header", true);
		self.$root.$emit("changeWhiteBackground", [false, true, "common"]);
		var element = document.getElementsByClassName("content-wrap");
		if (element.length != 0) {
		element[0].style.setProperty("background-color", "#149240");
        element[0].style.height = "114vh";
      if(window.innerWidth>1200){
        element[0].style.height = "121vh";
      }
		}
		var el = document.getElementsByClassName("wrapper");
		if (el.length != 0) {
		el[0].style.setProperty("background-color", "#149240");
		}
	},
	created() {
		if (isMobile) {
			this.mobile = true;
		}
		if (localStorage.getItem('session_data') == null) {
			this.$router.push("/login");
		}
	},
}
</script>
<style scoped>
.bg-findbank-success{
    background-color: #fff;
    border-radius: 10px;
}
.find-bank-success-button{
    background-color: #000;
    color:#fff;
    border-radius: 10px;
    border: none;
    padding: 15px 80px;
}
</style>
